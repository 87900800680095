import React, { useState } from 'react';
import UserForm from '../../components/AdminDashboard/UserForm';
import UserList from '../../components/AdminDashboard/UserList';
import AcessList from '../../components/AdminDashboard/AcessList';
// import FAQForm from '../../components/FAQForm/FAQForm';
// import FAQDelete from '../../components/FAQDelete/FaqDelete'; 
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('users'); 
  const [selectedFaqId, setSelectedFaqId] = useState(null); 

  return (
    <div className="admin-dashboard">
      <nav>
        <button onClick={() => setActiveTab('users')}>Cadastrar Usuário</button>
        <button onClick={() => setActiveTab('listUsers')}>Listar Usuários</button>
        <button onClick={() => setActiveTab('listAccess')}>Listar Acessos</button>
        {/* <button onClick={() => setActiveTab('faqform')}>Criar FAQ</button> */} 
        {/* <button onClick={() => setActiveTab('deleteFaq')}>Deletar FAQ</button> */} 
      </nav>
      <div className="content">
        {activeTab === 'users' && <UserForm />}
        {activeTab === 'listUsers' && <UserList />}
        {activeTab === 'listAccess' && <AcessList />}
        {/* {activeTab === 'faqform' && <FAQForm />} */} 
        {/* {activeTab === 'deleteFaq' && <FAQDelete id={selectedFaqId} setSelectedFaqId={setSelectedFaqId} />} */} 
      </div>
    </div>
  );
};

export default AdminDashboard;
